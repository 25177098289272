<template>
  <div class="popup-wrapper">
    <header class="popup-header">
      <div class="popup-title"><span v-show="(isGatelysUser)">{{ address }}</span></div>
      <div class="popup-buttons">
        <button type="button" class="button is-small is-light" @click="zoomTo">
          <i class="fa fa-search-plus fa-lg" aria-hidden="true"></i>
          <font-awesome-icon icon="search-plus" size="lg"> </font-awesome-icon>
        </button>
        <button
          type="button"
          class="button is-small is-light"
          @click="closePopup"
        >
          <font-awesome-icon icon="times" size="lg"> </font-awesome-icon>
        </button>
      </div>
    </header>
    <div class="popup-content">
      <!-- GATELYS FORM BLOCK -->
      <div v-if="isLoading">{{$t("general.loading")}}</div>
      <div v-else class="container" v-show="isGatelysUser">
        <h5 class="title is-5 status-title">{{ statusName }}</h5>
        <div v-show="isNormal" class="status-icon">
          <font-awesome-icon
            icon="check"
            size="2x"
            class="status-normal"
          ></font-awesome-icon>
          <div v-show="isGatelysUser">
            <b-button type="is-light" @click="reportFault">{{
              $t("lampStatus.reportFaultButton")
            }}</b-button>
          </div>
        </div>

        <div v-show="isRegistered" class="status-icon">
          <font-awesome-icon
            icon="exclamation-triangle"
            size="2x"
            class="status-registered"
          ></font-awesome-icon>
          <p class="description">{{ $t("status.description.registered") }}</p>
        </div>

        <div v-show="isCreated" class="status-icon">
          <font-awesome-icon
            icon="power-off"
            size="2x"
            class="status-created"
          ></font-awesome-icon>

          <p class="description">{{ $t("status.description.confirmed") }}</p>
        </div>

        <div v-show="isInProgress" class="status-icon">
          <font-awesome-icon
            icon="play"
            size="2x"
            class="status-in-progress"
          ></font-awesome-icon>

          <p class="description">{{ $t("status.description.inProgress") }}</p>
        </div>
        
        <div v-show="isPlanned" class="status-icon">
          <font-awesome-icon
            icon="hammer"
            size="2x"
            class="status-planned"
          ></font-awesome-icon>
          <p class="description">{{ $t("status.description.planned") }}</p>
        </div>
        
      </div>
      <!-- GATELYS CHANGE BLOCK -->

      <!-- OWNER CHANGE BLOCK - EIERBYTTE -->
      <div class="container" v-show="isServiceUser">
        <h5 class="title is-5 status-title">{{ owner }}</h5>
        <table>
          <tr>
            <td>{{ $t("faultForm.ownerChange.objectId") }}:</td>
            <td>{{ objectId }}</td>
          </tr>
          <tr>
            <td>{{ $t("faultForm.ownerChange.ean") }}:</td>
            <td>{{ ean }}</td>
          </tr>
        </table>

        <div>
          <b-button type="button" @click="reportFault">
            {{ $t("faultForm.ownerChange.btn") }}
          </b-button>
        </div>
      </div>
      <!--END OF OWNER CHANGE BLOCK -->

      <!-- SLA CHANGE BLOCK -->
      <div class="container" v-show="isSlaUser">
        <h5 class="title is-5 status-title">{{ owner }}</h5>
        <table>
          <tr>
            <td>{{ $t("faultForm.slaChange.sla") }}:</td>
            <td>{{ sla }}</td>
          </tr>
        </table>

        <div>
          <b-button type="button" @click="reportFault">
            {{ $t("faultForm.slaChange.btn") }}
          </b-button>
        </div>
      </div>
      <!-- END OF SLA CHANGE BLOCK -->

    </div>
  </div>
</template>

<script lang="js">
import Vue from 'vue';
import { getPopupStatus, MELDING_STATUS } from '../enums/melding-status';
import { isServiceUser, isSlaUser, isGatelysUser } from "../utils";
import {Config} from '@/config';
import Point from '@arcgis/core/geometry/Point';
import { eventBus } from '../event-bus';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes, faSearchPlus, faCheck, faExclamationTriangle, faPowerOff, faPlay, faPhoneSquare, faHammer } from '@fortawesome/free-solid-svg-icons';

library.add(faTimes, faSearchPlus, faCheck, faExclamationTriangle, faPowerOff, faPlay, faPhoneSquare, faHammer)

export default Vue.extend({
    name: 'PopupContent',
    props: {
        x: Number,
        y: Number,
        assetId: Number,
        status: null,
        address: String,
        objectId: Number,
        globalId: String,
        ean: String,
        owner: String,
        sla: String,
        view: {}
    },
    methods: {
        // takes properties from popup content and send to fault form
        reportFault: function() {
          eventBus.$emit('report-fault', { x: this.x, y: this.y, gisId: this.assetId, address: this.address, objectId: this.objectId, globalId: this.globalId, ean: this.ean, currentOwner: this.owner, sla: this.sla });
        },
        closePopup: function () { // TODO better will be emit to parent (popup holder) and control visibility from holder, close can be general responsibility of popup holder
            this.view.popup.close() 
        },
        zoomTo: function () {
            this.view.goTo({
                target: new Point({
                    x: this.x, 
                    y: this.y, 
                    spatialReference: {
                        wkid: Config.ext.mapconfig.baseLayer.wkid}}),
                        zoom: Config.ext.mapconfig.maxZoom
            });
        }, 
      
    },
    computed: {
        statusName: function() {
            return getPopupStatus(this.status);
        },
        isNormal: function() {
            return (
                this.status === null ||
                this.status === MELDING_STATUS.NORMAL_DRIFT ||
                this.status === MELDING_STATUS.JOB_DONE ||
                this.status === MELDING_STATUS.JOB_DOCUMENTED_COMPLETED
            );
        },
        isPlanned: function() {
          return this.status === MELDING_STATUS.PLANNED
        },
        isRegistered: function() {
            return this.status === MELDING_STATUS.REGISTERED;
        },
        isCreated: function() {
            return this.status === MELDING_STATUS.JOB_CREATED_NOT_DELEGATED;
        },
        isInProgress: function() {
            return this.status === MELDING_STATUS.JOB_ONGOING || this.status === MELDING_STATUS.JOB_ON_HOLD || this.status === MELDING_STATUS.JOB_ASSIGNED;
        },
     
        isServiceUser: function () {
          return isServiceUser();
        },
        isSlaUser: function () {
          return isSlaUser();
        },
        isGatelysUser: function() {
          return isGatelysUser();
        },
        isLoading: function(){
          return this.status === undefined; 
        }
    }
});
</script>

<style lang="scss" scoped>
* {
  font-family: "LyseSans", sans-serif;
}
.popup-content {
  text-align: center;
  .status-normal {
    color: #38a800;
  }
  .status-registered {
    color: #ffa900;
  }
  .status-created {
    color: #ff0000;
  }
  .status-in-progress {
    color: #006fff;
  }
  .status-planned {
    color: #C500FF;
  }
}

.popup-header {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  margin-bottom: 0.75rem;
  .popup-title {
    flex: 1;
  }
  .popup-buttons {
    display: flex;
  }
}

i {
  padding-bottom: 5px;
}
p.description {
  font-size: 0.7rem;
  padding-top: 10px;
}
.title.status-title.is-5 {
  margin-bottom: 1rem;
}

table {
  margin: 0 auto 1rem auto;
  font-size: 14px;
  
  td {
    text-align: left;
  }
  td:first-child {
    font-weight: 700;
    text-align: right;
    padding-right: 10px;
  }
}
</style>
