<template>
  <div id="appka">
    <b-sidebar
      type="is-light"
      :overlay="true"
      :open.sync="openSidebar"
      position="fixed"
    >
      <component v-bind:is="sidebarComponent"></component>
    </b-sidebar>

    <b-navbar :fixed-top="true">
      <template slot="brand">
        <b-navbar-item class="is-hidden-touch" href="/" v-if="!hasLoginEmbed">
          <img src="./assets/LyseLogo.png" alt="Lyse logo" />
        </b-navbar-item>
        <b-navbar-item class="is-hidden-desktop">
          <Geolocation :view="view" />
          <CustomSearch :view="view" :class="{'searchbox-embed-param': hasLoginEmbed }" />
        </b-navbar-item>
      </template>
      <template slot="start" >
        <b-navbar-item v-if="!hasLoginEmbed" href="#" @click="openSidebarComponent('OurServices')">{{
          $t("nav.ourServices")
        }}</b-navbar-item>
        <b-navbar-item 
          v-if="isGatelysUser"
          href="#"
          @click="openSidebarComponent('ReportFault')"
          >{{ $t("nav.reportFault") }}</b-navbar-item
        >
        <b-navbar-item href="#" @click="openSidebarComponent('Legend')">{{
          $t("nav.legend")
        }}</b-navbar-item>
      </template>
      <template slot="end" >
        <b-navbar-item class="is-hidden-touch" :class="{ 'searchbox-embed-param': hasLoginEmbed }">
          <Geolocation :view="view" />
          <CustomSearch :view="view" :class="{ 'searchbox-embed-param': hasLoginEmbed }"/>
        </b-navbar-item>


     
        <b-navbar-item v-if="!hasLoginEmbed">
          <LanguageSelector />
        </b-navbar-item>
        <b-navbar-item v-if="isLoggedIn && !hasLoginEmbed">
          <b-button v-on:click="logoutAction()">Logout</b-button>
        </b-navbar-item>
      </template>

      <template slot="burger" hidden></template>
    </b-navbar>

    <!-- v-if="(!isServiceUser) || (isServiceUser && isLoggedIn)" -->
    <div class="columns map-holder m-0">
      <div class="column is-12 p-0 map-holder-inner">
        <WebMap :view="view"></WebMap>
        <FaultForm :view="view" v-if="showForm" ref="faultForm" />
      </div>
    </div>
  </div>
</template>

<script lang="js">
import WebMap from './components/WebMap.vue';
import OurServices from './components/OurServices.vue';
import ReportFault from './components/ReportFault.vue';
import About from './components/About.vue';
import LoginForm from './components/LoginForm.vue';
import CustomSearch from './components/CustomSearch.vue';
import Geolocation from './components/Geolocation.vue';
import Legend from './components/Legend.vue';
import FaultForm  from './components/FaultForm.vue';
import LanguageSelector  from './components/LanguageSelector.vue';
import Point from '@arcgis/core/geometry/Point';
import MapView from '@arcgis/core/views/MapView';
import Map from '@arcgis/core/Map';
import TileLayer from '@arcgis/core/layers/TileLayer';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import PopupTemplate from '@arcgis/core/PopupTemplate';
import ScaleBar from '@arcgis/core/widgets/ScaleBar';
import { Config } from './config';
import { eventBus } from './event-bus';

import { title } from './config';
import i18n from "@/i18n";
import { isServiceUser, isSlaUser, isGatelysUser } from './utils';
import { isLoggedIn, hasLoginEmbed, logout } from './auth';

import './css/index.scss';

export default {
    name: 'App',
    components: {   WebMap,
                    CustomSearch,
                    Geolocation,
                    ReportFault,
                    Legend,
                    FaultForm,
                    OurServices,
                    About ,
                    LanguageSelector,
                    LoginForm
    },
    data: () => ({
        title,
        openSidebar:false,
        currentSidebarComponent: '',
        x: null,
        y: null,
        view: {},
        map: {},
        showForm: false,
        isLoggedIn: false,
        hasLoginEmbed: false


    }),
    computed : {
        sidebarComponent() {
            return this.currentSidebarComponent;
        },
        isServiceUser() { return isServiceUser()},
        isSlaUser() {return isSlaUser()},
        isGatelysUser() {return isGatelysUser()}
    },
    methods: {
        openSidebarComponent: function(componentName) {
            this.openSidebar = true;
            this.currentSidebarComponent = componentName;
        },

        getCustomParamDisableCache() {
          return {
            cacheParam: Date.now()
          }
        },

        mapBase() {
            return new TileLayer({
                  url: Config.ext.mapconfig.baseLayer.url,
                  id: 'gcbuilder'
            });
        },

        mapOwners() {
            const ownerLayerUrl = Config.ext.mapconfig.ownerLayer.url;
            const notificationLayerUrl = Config.ext.mapconfig.notificationLayer.url;
            const customParameters = this.getCustomParamDisableCache();

            const notificationLayer = new FeatureLayer({
                url: notificationLayerUrl,
                id: 'notification-layer',
                spatialReference: new SpatialReference({ wkid: Config.ext.mapconfig.notificationLayer.wkid }),
                popupEnabled: false,
                visible: false,
                customParameters
            });

            const ownerLayer = new FeatureLayer({
                url: ownerLayerUrl,
                id: 'owners-layer',
                // outFields: ['VED_ADRESSE'],
                spatialReference: new SpatialReference({ wkid: Config.ext.mapconfig.ownerLayer.wkid }),
                popupEnabled: true,
                popupTemplate: new PopupTemplate({
                    outFields: ['VED_ADRESSE', 'ASSETID', 'OBJECTID', 'KUNDE', 'EAN_NR', 'SLA_KRAV'],
                    content: '',
                    title: ''
                }),
                definitionExpression: [
                  "SLA_KRAV <> '9999' OR SLA_KRAV IS NULL",
                ],
                customParameters
            });

            return new Map({
                layers: [this.mapBase(), notificationLayer, ownerLayer]
            });
        },

        mapNotification() {
            const armaturLayerUrl = Config.ext.mapconfig.armaturLayer.url;
            const meldingLayer = Config.ext.mapconfig.notificationLayer.url;
            const customParameters = this.getCustomParamDisableCache();

            const armaturLayer = new FeatureLayer({
                url: armaturLayerUrl,
                id: 'lamps-layer',
                spatialReference: new SpatialReference({ wkid: Config.ext.mapconfig.armaturLayer.wkid }),
                popupEnabled: true,
                popupTemplate: new PopupTemplate({
                    outFields: ['VED_ADRESSE', 'ASSETID', 'OBJECTID', 'SLA_KRAV'],
                    content: '',
                    title: ''
                }),
                definitionExpression: [
                  "SLA_KRAV <> '9999' OR SLA_KRAV IS NULL",
                ],
                customParameters
            });

            const notificationLayer = new FeatureLayer({
                url: meldingLayer,
                id: 'notification-layer',
                spatialReference: new SpatialReference({ wkid: Config.ext.mapconfig.notificationLayer.wkid }),
                popupEnabled: false,
                customParameters
            });

            return new Map({
                layers: [this.mapBase(), armaturLayer, notificationLayer]
            });
        },

        mapSLAs() {
          const SLAlayerUrl = Config.ext.mapconfig.slaLayer.url;
          const notificationLayerUrl = Config.ext.mapconfig.notificationLayer.url;
          const customParameters = this.getCustomParamDisableCache();

          const notificationLayer = new FeatureLayer({
                url: notificationLayerUrl,
                id: 'notification-layer',
                spatialReference: new SpatialReference({ wkid: Config.ext.mapconfig.notificationLayer.wkid }),
                popupEnabled: false,
                visible: false,
                customParameters
            });

            const SLAlayer = new FeatureLayer({
                url: SLAlayerUrl,
                id: 'slas-layer',
                // outFields: ['VED_ADRESSE'],
                spatialReference: new SpatialReference({ wkid: Config.ext.mapconfig.slaLayer.wkid }),
                popupEnabled: true,
                popupTemplate: new PopupTemplate({
                    outFields: ['VED_ADRESSE', 'ASSETID', 'OBJECTID', 'KUNDE', 'SLA_KRAV'],
                    content: '',
                    title: ''
                }),
                definitionExpression: [
                  "SLA_KRAV <> '9999' OR SLA_KRAV IS NULL",
                ],
                customParameters
            });
            return new Map({
                layers: [this.mapBase(), notificationLayer, SLAlayer]
            });
        },

        checkAuthentification() {
            if (this.isServiceUser && !this.isLoggedIn) {
                this.$buefy.modal.open({
                    parent: this,
                    component: LoginForm,
                    hasModalCard: true,
                    trapFocus: true,
                    canCancel: false
                });
            }

            if (this.isSlaUser && !this.isLoggedIn) {
                this.$buefy.modal.open({
                    parent: this,
                    component: LoginForm,
                    hasModalCard: true,
                    trapFocus: true,
                    canCancel: false
                });
            }
        },
        logoutAction() {
          logout();
          location.reload();
        }
    },
    mounted() {
        console.log("Mounted App!");
    },
    created() {
        i18n.locale = Config.ext.defaultLocale;

        eventBus.$on('report-fault', selected => {
            if(selected != null){
                setTimeout(() => {
                  eventBus.$emit('report-fault-data', selected);
                }, 50);
                this.showForm = true;
                this.view.goTo({target: new Point({x: selected.x, y: selected.y, spatialReference: {wkid:Config.ext.mapconfig.baseLayer.wkid}}),zoom: Config.ext.mapconfig.maxZoom});

                this.$nextTick(()=>{
                    this.$smoothScroll({
                        scrollTo: this.$refs.faultForm.$el,
                        duration: 1000,
                        // offset: -50,
                        updateHistory: true
                    });
                });
            } else {
                this.showForm = false;
            }
        });

        eventBus.$on('faultFormChanged', faultForm => {
            if(faultForm === null){
                this.showForm = false;
            }
        });

        /* INITIAL IMPLEMENTATION
        this.map = (isServiceUser())
                        ? this.mapOwners()
                        : this.mapNotification();
                        */
          this.map = isServiceUser()? this.mapOwners(): 
                                isSlaUser()? this.mapSLAs():
                                            this.mapNotification();      

        this.view = new MapView({
            view: this.view,
            map: this.map,
            center: new Point({
                x: Config.ext.mapconfig.initCenter.x,
                y: Config.ext.mapconfig.initCenter.y,
                spatialReference: new SpatialReference({ wkid: Config.ext.mapconfig.initCenter.wkid })
            }),
            zoom: Config.ext.mapconfig.initCenter.zoom
        });

         var scaleBar = new ScaleBar({
          view: this.view,
          unit: "metric" // The scale bar displays both metric and non-metric units.
        });

        // Add the widget to the bottom left corner of the view
        this.view.ui.add(scaleBar, {
          position: "bottom-left"
        });

        // change cursor on mouse over the point
        this.view.on("pointer-move", (event) => {
            this.view.hitTest(event).then((response) => {
              if (response.results.length > 0) {
                this.$el.style.cursor = "pointer";
              }
              else {
                this.$el.style.cursor = "default";
              }
            });
        });

        (async () => {
          console.log(this.$route.path)
         
          this.hasLoginEmbed = await hasLoginEmbed(this.$route.path);
          this.isLoggedIn = await isLoggedIn();
        })();
    }
};
</script>

<style lang="scss" scoped>
@import "https://js.arcgis.com/4.19/@arcgis/core/assets/esri/themes/light/main.css";

#appka {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;

  .map-holder {
    height: 100%;
    .map-holder-inner {
      height: 100%;
    }
  }
}
.searchbox-embed-param {

    width: 400px !important;
  
}

</style>
